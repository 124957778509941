import { request, noTimeOutReq } from '@/utils/request.js'

//展示所有路由信息
export function getUrlListApi (sysAuthUrlId) {
  return request({
    url: `/sys/auth/url/${sysAuthUrlId}/list`,
    method: 'post',
  })
}

//展示某个路由及其所有子路由
export function getUrlApi (sysAuthUrlId) {
  return request({
    url: `/sys/auth/url/${sysAuthUrlId}/get`,
    method: 'post',
  })
}

//添加路由
export function addUrlApi (route) {
  return request({
    url: `/sys/auth/url/add`,
    method: 'post',
    data: route,
  })
}

//修改路由
export function updateUrlApi (url) {
  return request({
    url: `/sys/auth/url/update`,
    method: 'post',
    data: url,
  })
}

//删除路由
export function removeUrlApi (idList) {
  return request({
    url: `/sys/auth/url/delete`,
    method: 'post',
    data: idList,
  })
}
