import { request, noTimeOutReq } from '@/utils/request.js'

//展示角色信息
export function findRolePageApi (pageIndex, pageSize) {
  return request({
    url: `/sys/auth/role/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
    // data: data,
  })
}

//删除角色
export function removeRoleApi (idList) {
  return request({
    url: `/sys/auth/role/delete`,
    method: 'post',
    data: idList,
  })
}

//获取角色详情
export function getRoleByIdApi (sysAuthRoleId) {
  return request({
    url: `/sys/auth/role/${sysAuthRoleId}/get`,
    method: 'post',
  })
}

//新增角色
export function addRoleApi (sysAuthRole) {
  return request({
    url: `/sys/auth/role/add`,
    method: 'post',
    data: sysAuthRole,
  })
}

//修改角色
export function updateRoleApi (sysAuthRole) {
  return request({
    url: `/sys/auth/role/update`,
    method: 'post',
    data: sysAuthRole,
  })
}

//检查角色-路由 是否关联
export function checkRoleUrlApi (sysAuthRoleId, sysAuthUrlId) {
  return request({
    url: `/sys/auth/role/url/get`,
    method: 'post',
    data: {
      sysAuthRoleId: sysAuthRoleId,
      sysAuthUrlId: sysAuthUrlId,
    },
  })
}

//修改角色-路由
export function updateRoleUrlApi (sysAuthRoleId, urlIdList) {
  return request({
    url: `/sys/auth/role/url/${sysAuthRoleId}/update`,
    method: 'post',
    data: urlIdList,
  })
}

//修改角色-接口
export function updateRoleApiApi (sysAuthRoleId, apiIdList) {
  return request({
    url: `/sys/auth/role/api/${sysAuthRoleId}/update`,
    method: 'post',
    data: apiIdList,
  })
}
